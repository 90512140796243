<template>
<Transition
  @before-enter="beforeEnter"
  @before-leave="beforeLeave"
  @after-enter="afterEnter"
  :appear="appear"
  :name="name">
  <slot></slot>
</Transition>
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps({
  sm: {
    type: Boolean,
    default: false
  },
  md: {
    type: Boolean,
    default: false
  },
  lg: {
    type: Boolean,
    default: false
  },
  delay: {
    type: Boolean,
    default: false
  },
  appear: {
    type: Boolean,
    default: false
  },
  slow: {
    type: Boolean,
    default: false
  },
  slowest: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const name = computed(() => {
  if (props.disabled) {
    return 'fr-reveal-disabled'
  }

  if (props.sm) {
    return 'fr-reveal-sm'
  }

  if (props.md) {
    return 'fr-reveal-md'
  }

  if (props.lg) {
    return 'fr-reveal-lg'
  }

  return 'fr-reveal-xl'
})

const beforeEnter = (el) => {
  if (props.slow) {
    el.classList.add('slow')
  } else if (props.slowest) {
    el.classList.add('slowest')
  }

  if (props.delay) {
    if (props.slow) {
      el.classList.add('delay-500')
    } else if (props.slowest) {
      el.classList.add('delay-[2s]')
    } else {
      el.classList.add('delay-200')
    }
  }
}

const beforeLeave = (el) => {
  if (props.slow) {
    el.classList.add('slow')
  } else if (props.slowest) {
    el.classList.add('slowest')
  }
}

const afterEnter = (el) => {
  if (props.slow) {
    el.classList.remove('slow')
  } else if (props.slowest) {
    el.classList.remove('slowest')
  }

  if (props.delay) {
    if (props.slow) {
      el.classList.remove('delay-500');
    } else if (props.slowest) {
      el.classList.remove('delay-[2s]');
    } else {
      el.classList.remove('delay-200');
    }
  }

}
</script>
<style scoped>
.fr-reveal-sm-enter-active,
.fr-reveal-sm-leave-active,
.fr-reveal-md-enter-active,
.fr-reveal-md-leave-active,
.fr-reveal-lg-enter-active,
.fr-reveal-lg-leave-active,
.fr-reveal-xl-enter-active,
.fr-reveal-xl-leave-active {
  @apply transition-all duration-200 overflow-hidden;
}

.fr-reveal-sm-enter-active.slow,
.fr-reveal-sm-leave-active.slow,
.fr-reveal-md-enter-active.slow,
.fr-reveal-md-leave-active.slow,
.fr-reveal-lg-enter-active.slow,
.fr-reveal-lg-leave-active.slow,
.fr-reveal-xl-enter-active.slow,
.fr-reveal-xl-leave-active.slow {
  @apply duration-[.5s];
}

.fr-reveal-sm-enter-active.slowest,
.fr-reveal-sm-leave-active.slowest,
.fr-reveal-md-enter-active.slowest,
.fr-reveal-md-leave-active.slowest,
.fr-reveal-lg-enter-active.slowest,
.fr-reveal-lg-leave-active.slowest,
.fr-reveal-xl-enter-active.slowest,
.fr-reveal-xl-leave-active.slowest {
  @apply duration-[2s];
}

.fr-reveal-sm-enter-active,
.fr-reveal-md-enter-active,
.fr-reveal-lg-enter-active,
.fr-reveal-xl-enter-active {
  @apply ease-in-quick;
}

.fr-reveal-sm-leave-active,
.fr-reveal-md-leave-active,
.fr-reveal-lg-leave-active,
.fr-reveal-xl-leave-active {
  @apply ease-out-quick;
}

.fr-reveal-sm-enter-to,
.fr-reveal-sm-leave-from,
.fr-reveal-md-enter-to,
.fr-reveal-md-leave-from,
.fr-reveal-lg-enter-to,
.fr-reveal-lg-leave-from,
.fr-reveal-xl-enter-to,
.fr-reveal-xl-leave-from {
  @apply opacity-100;
}

.fr-reveal-sm-enter-to,
.fr-reveal-sm-leave-from {
  @apply max-h-[200px];
}
.fr-reveal-md-enter-to,
.fr-reveal-md-leave-from {
  @apply max-h-[500px];
}
.fr-reveal-lg-enter-to,
.fr-reveal-lg-leave-from {
  @apply max-h-[800px];
}
.fr-reveal-xl-enter-to,
.fr-reveal-xl-leave-from {
  @apply max-h-super-giant;
}

.fr-reveal-sm-leave-to,
.fr-reveal-sm-enter-from,
.fr-reveal-md-leave-to,
.fr-reveal-md-enter-from,
.fr-reveal-lg-leave-to,
.fr-reveal-lg-enter-from,
.fr-reveal-xl-leave-to,
.fr-reveal-xl-enter-from {
  @apply opacity-0 max-h-0;
}
</style>
